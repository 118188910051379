@font-face {
  font-family: 'Lazer84';
  src: local('Lazer84'), url(./fonts/Lazer84.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
:root {
  --bg-color: #DDD;
  --cassette-bg: #333;
  --cassette-bg-shadow: rgb(41, 40, 40);
  --tape-color: rgb(119, 56, 56);
  --teeth: #CCC;
  --writing: rgb(43, 43, 170);
  --cassette-green: #80BF82;
  --cassette-orange: #F5864F;
  --cassette-pink: #B86089;
  --cassette-purple: #8172C4;
  --cassette-blue: #72B4D7;
  --cassette-brown: #AF8656;
}
body {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: var(--bg-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
#root {
  height: 100vh;
  width: 100vw;
}
img.album {
  height: 25%;
  width: 25%;
  align-self: center;
  margin-top: 5%;
}
.main {
  display: flex;
  justify-content: space-around;
}
.title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3rem;
  letter-spacing: .7rem;
  width: 100%;
  text-align: center;
  font-family: 'Lazer84', sans-serif;
  color: var(--cassette-bg-shadow);
}
.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
audio {
  display: none;
  width: 0;
  height: 0;
}
.player {
  width: 250px;
  height: 80px;
  border-radius: 10px;
  margin: auto 15% auto 15%;
  background: var(--cassette-bg-shadow);
}
.control {
  height: 50px;
  border-radius: 10px;
  max-width: 50px;
  background: var(--cassette-bg);
  padding: 0;
  border-left: 1px solid var(--bg-color);
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
.fa {
  margin: 18px;
  color: var(--bg-color);
}
.control:hover {
  background: #1A1A1A;
}
.progress {
  position: relative;
  top: 11px;
  left: 25px;
  right: 25px;
  max-width: 200px;
  height: 6px;
  background: transparent;
  z-index: 0;
  background: var(--bg-color);
}
.progress .bar {
  cursor: pointer;
  top: 11px;
  left: 25px;
  right: 25px;
  max-width: 200px;
  width: 0;
  height: 6px;
  background: transparent;
  z-index: -1;
  pointer-events: none;

}
.progress .indicator {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 6px;
  z-index: -2;
  max-width: 200px;
  background: red;
  height: 100%;
}
.control.pushed {
  background: var(--cassette-bg-shadow);
  border-top: none;
  border-left: none;
  width: 52px;
  height: 48px;
}
.volume {
  left: 20px;
  overflow: hidden;
  width: 50px;
  height: 130px;
  border-radius: 10px;
  margin: 20px auto auto -50px;
  background: var(--cassette-bg-shadow);
}
.vol-plus,
.vol-minus {
  position: relative;
  height: 50px;
  border-radius: 10px;
  max-width: 50px;
  background: var(--cassette-bg);
  padding: 0;
  border-left: 1px solid var(--bg-color);
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
.vol-plus {
  top: 0;
}
.vol-minus {
  top: -20px;
}
p {
  position: relative;
  color: var(--bg-color);
  text-align: center;
  justify-content: center;
  top: -9px;
  font-weight: bold;
}

@media screen and (min-width: 990px) {
  .cassette {
    order: 2;
  }
  img.album {
    max-height: 40%;
    max-width: 40%;
  }
}
@media screen and (max-width: 720px) {
  .main {
    display: block;
    justify-content: center;
  }
  .cassette{
    left: calc(50% - 250px);
    right: calc(50% - 250px);
  }
  img.album {
    height: 60%;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
}
@media screen and (max-width: 620px) {
  .cassette{
    left: calc(50% - 250px);
    right: calc(50% - 250px);
  }
}

.cassette {
  margin-top: 7%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 315px;
  background-color: var(--cassette-bg);
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgb(0, 0, 0, .5);
  border-top: 2px solid #555;
  border-bottom: 2px solid #222;
}
.cassette::before {
  content: '';
  width: 5px;
  height: 100px;
  position: absolute;
  left: -10px;
  bottom: 30px;
  border-right: 5px solid var(--cassette-bg-shadow);
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.cassette::after {
  content: '';
  width: 5px;
  height: 100px;
  position: absolute;
  right: -10px;
  bottom: 30px;
  border-left: 5px solid var(--cassette-bg-shadow);
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
}


.wheels-wrapper {
  position: relative;
  width: 300px;
  height: 80px;
  margin-bottom: 25px;
  /*background: var(--cassette-bg);*/
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: 1px solid transparent;
  background: linear-gradient(to right, var(--cassette-bg), var(--cassette-bg)), linear-gradient(0deg, var(--cassette-bg), grey);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
}
.wheel {
  position: relative;
  width: 60px;
  height: 60px;
}
.wheel-left.playing {
  animation: rolling 3s linear infinite;
}
.wheel-right.playing {
  animation: rolling 4s linear infinite;
}
@keyframes rolling {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.wheel-shadow {
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, .3) 100%);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  z-index: -1;
}
.wheel-shadow-left {
  left: 7px;
  top: 7px;
}
.wheel-shadow-right {
  right: 7px;
  top: 7px;
}
.teeth-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid var(--teeth);
  border-radius: 50%;
  background: var(--bg-color);
}
.teeth-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, .2) 55%,
    rgba(0, 0, 0, .5) 80%
  );
  border-radius: 50%;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.tooth {
  position: absolute;
  width: 90%;
  height: 9px;
  box-shadow: inset var(--teeth) 8px 0 0 0, inset var(--teeth) -8px 0 0 0;
}
.tooth:nth-of-type(2) {
  transform: rotate(45deg);
}
.tooth:nth-of-type(3) {
  transform: rotate(90deg);
}
.tooth:nth-of-type(4) {
  transform: rotate(-45deg);
}
.window {
  position: relative;
  width: 130px;
  height: 60px;
  background: var(--bg-color) !important;
  /*border-radius: 10px;*/
  margin: 0 15px;
  overflow: hidden;
  border: 1.5px solid transparent;
  border-radius: 8px;
  background: linear-gradient(to right, white, white), linear-gradient(0deg, grey, black);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, .3);
}
.window::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  background: rgba(0, 0, 0, .3);
  z-index: 1;
}
.window .tape-left {
  position: absolute;
  top: -58%;
  left: -75%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, .5);
  background-image: repeating-radial-gradient(
    circle,
    var(--tape-color) 0px,
    var(--tape-color) 2px,
    #714739 2px,
    #714739 3px
  );
}
.window .tape-right {
  position: absolute;
  top: -95%;
  right: -85%;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  box-shadow: -1px 0px 5px rgba(0, 0, 0, .5);
  background-image: repeating-radial-gradient(
    circle,
    var(--tape-color) 0px,
    var(--tape-color) 2px,
    #714739 2px,
    #714739 3px
  );
}


.screw {
  position: absolute;
  width: 15px;
  height: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, .3) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: #5E5F5A solid .42rem;
  border-bottom: #5E5F5A solid .35rem;
  border-top: #C3C4C1 solid .4em;
  border-right: #C3C4C1 solid .4em;
  border-radius: 50%;
  box-shadow: var(--bg-color) 1px -0.2px 1px 0;
  transform: rotate(-50deg);
}
.screw::after {
  position: absolute;
  top: -10px;
  left: -9px;
  display: block;
  content: "";
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: linear-gradient(-140deg, rgba(0, 0, 0, .1) 0%, rgba(255, 255, 255, .3) 100%);
  z-index: -1;

}
.screw span {
  display: block;
  width: 0.5em;
  height: 0.15em;
  border-radius: .08em;
  background: #222;
}
.screw span::after {
  display: block;
  content: "";
  width: 0.5em;
  height: 0.15em;
  border-radius: .08em;
  background: #222;
  transform: rotate(90deg);
}


.screw.screw-tl span {
  transform: rotate(-20deg);
}
.screw.screw-tr span {
  transform: rotate(10deg);
}
.screw.screw-bl span {
  transform: rotate(-5deg);
}
.screw.screw-br span {
  transform: rotate(39deg);
}


.screw-tl {
  top: 8px;
  left: 8px;
}
.screw-tr {
  top: 8px;
  right: 8px;
}
.screw-bl {
  bottom: 8px;
  left: 8px;
}
.screw-br {
  bottom: 8px;
  right: 8px;
}

.screw-bottom {
  position: relative;
  transform: rotate(-50deg) scale(.8);
  margin-bottom: 30px;

}

.bottom {
  width: 335px;
  height: 75px;
  position: absolute;
  bottom: 0;
  left: 80px;
  background: linear-gradient(-20deg, rgba(0, 0, 0, 1) 30%, rgba(255, 255, 255, .6) 100%);
  clip-path: polygon(5% 0, 95% 0, 100% 100%, 0 100%);
  z-index: 10000;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 40px;
}
.bottom::after {
  content: '';
  width: 332px;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 2px;
  top: 2px;
  background: linear-gradient(to bottom, var(--cassette-bg), var(--bg-color) 1000%, var(--cassette-bg));
  z-index: -1;
  background-size: 100% 3px;
  clip-path: polygon(5% 0, 95% 0, 100% 100%, 0 100%);
}


.holes {
  display: flex;
  align-items: flex-end;
  z-index: 0;
}
.hole {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--bg-color);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .7)
}
.hole::after {
  content: '';
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, .3) 100%);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  z-index: -1;
  margin-left: -2px;
  margin-top: -2px;
  position: absolute;
}
.hole-square {
  width: 15px;
  height: 14px;
  border-radius: 2px;
  background: var(--bg-color);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .7);
}
.hole-square::after {
  content: '';
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, .3) 100%);
  border-radius: 4px;
  width: 19px;
  height: 19px;
  z-index: -1;
  margin-left: -2px;
  margin-top: -3px;
  position: absolute;
}
.hole-square.left {
  margin: 0 0 10px 30px;
}
.hole-square.right {
  margin: 0 30px 10px 0;
}


.label {
  position: absolute;
  width: 440px;
  height: 186px;
  top: 30px;
  left: 32px;
  border-radius: 10px;
  border: 1px solid var(--cassette-bg);
  background-size: 450px 450px;
  font-size: .7em;
  letter-spacing: -.1rem;
  text-overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
}
.label.blue {
    background-image: linear-gradient(
    180deg,
    #F4F5DF 21.33%,
    #F4F5DF 21.33%,
    #F4F5DF 26.67%,
    var(--cassette-blue) 26.67%,
    var(--cassette-blue) 50%,
    #F4F5DF 50%,
    #F4F5DF 71.33%,
    #F4F5DF 71.33%,
    #F4F5DF 76.67%,
    var(--cassette-blue) 76.67%,
    var(--cassette-blue) 100%);
}
.label.green {
    background-image: linear-gradient(
    180deg,
    #F4F5DF 21.33%,
    #F4F5DF 21.33%,
    #F4F5DF 26.67%,
    var(--cassette-green) 26.67%,
    var(--cassette-green) 50%,
    #F4F5DF 50%,
    #F4F5DF 71.33%,
    #F4F5DF 71.33%,
    #F4F5DF 76.67%,
    var(--cassette-green) 76.67%,
    var(--cassette-green) 100%);
}
.label.orange {
    background-image: linear-gradient(
    180deg,
    #F4F5DF 21.33%,
    #F4F5DF 21.33%,
    #F4F5DF 26.67%,
    var(--cassette-orange) 26.67%,
    var(--cassette-orange) 50%,
    #F4F5DF 50%,
    #F4F5DF 71.33%,
    #F4F5DF 71.33%,
    #F4F5DF 76.67%,
    var(--cassette-orange) 76.67%,
    var(--cassette-orange) 100%);
}
.label.pink {
    background-image: linear-gradient(
    180deg,
    #F4F5DF 21.33%,
    #F4F5DF 21.33%,
    #F4F5DF 26.67%,
    var(--cassette-pink) 26.67%,
    var(--cassette-pink) 50%,
    #F4F5DF 50%,
    #F4F5DF 71.33%,
    #F4F5DF 71.33%,
    #F4F5DF 76.67%,
    var(--cassette-pink) 76.67%,
    var(--cassette-pink) 100%);
}
.label.purple {
    background-image: linear-gradient(
    180deg,
    #F4F5DF 21.33%,
    #F4F5DF 21.33%,
    #F4F5DF 26.67%,
    var(--cassette-purple) 26.67%,
    var(--cassette-purple) 50%,
    #F4F5DF 50%,
    #F4F5DF 71.33%,
    #F4F5DF 71.33%,
    #F4F5DF 76.67%,
    var(--cassette-purple) 76.67%,
    var(--cassette-purple) 100%);
}
.label.brown {
    background-image: linear-gradient(
    180deg,
    #F4F5DF 21.33%,
    #F4F5DF 21.33%,
    #F4F5DF 26.67%,
    var(--cassette-brown) 26.67%,
    var(--cassette-brown) 50%,
    #F4F5DF 50%,
    #F4F5DF 71.33%,
    #F4F5DF 71.33%,
    #F4F5DF 76.67%,
    var(--cassette-brown) 76.67%,
    var(--cassette-brown) 100%);
}


.label::after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 444px;
  height: 190px;
  background: transparent;
  border-radius: .7em;
  border-top: #555 solid .1em;
  border-bottom: #999 solid .1em;
}
.label-writing {
  font-family: "Reenie Beanie", cursive;
  font-size: 2.2rem;
  color: var(--writing);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 420px;
  height: 50px;
  margin-top: 10px;
  border-radius: 10px;
  background: #F4F5DF;
  padding: 10px 20px 20px 20px;
}


